<template>
    <div class="scan-page">

        <div class="container">
            <div class="row mb-3">
                <div class="col-8">
                    <b-form-checkbox
                        v-model="scanningType"
                        name="check-button"
                        value="single"
                        unchecked-value="bulk"
                        switch
                    >
                        Scan: <b>{{ scanningTypeText }}</b>
                    </b-form-checkbox>
                </div>
                <div class="col-4 pt-2">
                    <router-link :to="{ name: 'zoneFinder'}" class="float-right back-button text-muted">Zone Finder <i class="fal fa-arrow-right"></i></router-link>
                </div>
            </div>

            <scanner @barcodeHasBeenScanned="barcodeScanned($event)"></scanner>

            <empty-state
                v-if="!location"
                emptyIcon
                icon="scanner"
                inline
                small
                title=""
                subtitle="Scan the Location QR Code">
            </empty-state>

            <template v-if="location">
                <div class="row mt-3">
                    <div class="col-12">
                        <label>{{ locationName }}</label>
                        <button class="btn btn-info btn-sm float-right" @click="reloadPage()">Change</button>
                    </div>
                </div>

                <empty-state
                    v-if="!scannedItems.length && !bulkMoveConfirmationDetailsAvailable && !relocating"
                    emptyIcon
                    icon="scanner"
                    inline
                    small
                    title=""
                    :subtitle="startScanningText">
                </empty-state>

                <empty-state
                    v-if="relocating"
                    statusIcon="loading"
                    title="Relocating Scanned Stock"
                    :subtitle="`Scanned stock is being moved to ${locationName}`">
                </empty-state>

                <div class="row mt-3" v-if="scanningType === 'single' && scannedItems.length && !relocating">
                    <div class="col-12">
                        <p class="text-sm text-success mt-0 mb-0">
                            Total Items Scanned: {{ scannedItems.length }}
                        </p>
                    </div>
                    <div class="col-12">
                        <div class="card">
                            <ul class="checklist my-0">
                                <li
                                    v-for="item in scannedItems"
                                    :key="item.id"
                                    class="checklist-item no-hover complete"
                                >
                                    <span class="text-sm">
                                        {{ item.type }}: {{ item.id }}
                                        <br/>
                                        {{ item.name }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <template v-if="scanningType === 'bulk' && location && !bulkMoveConfirmed && bulkMoveConfirmationDetailsAvailable">

                    <empty-state
                        statusIcon="warning"
                        :title="bulkMoveConfirmationText"
                        subtitle="Are you sure you want to complete this move?">
                    </empty-state>

                    <div class="row mt-3" >
                        <div class="col-6">
                            <button class="btn btn-success btn-block d-md-inline-block mb-3" @click="confirmBulkMove">Confirm</button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-info btn-block d-md-inline-block mb-3" @click="clearSelections">Cancel</button>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    props: ['passedLocation'],

    data() {
        return {
            scanningType: 'single',
            location: null,
            barcode: null,
            scannedItems: [],
            bulkMoveConfirmed: false,
            bulkMoveConfirmationDetails: {},
            relocating: false
        }
    },

    mounted() {
        if (this.passedLocation) {
            this.location = this.passedLocation;
        }
    },

    computed: {
        locationName() {
            if (!this.location) return "";
            return `${this.location.name} - ${this.location.code}`;
        },

        scanningTypeText() {
            return this.scanningType === 'single' ? "Single Items" : "Bulk Loc. to Loc.";
        },

        startScanningText() {
            if (this.scanningType === 'single') {
                return "Start scanning stock to this Location";
            }
            return "Scan the destination Location";
        },

        bulkMoveConfirmationDetailsAvailable() {
            return Object.keys(this.bulkMoveConfirmationDetails).length > 0;
        },

        bulkMoveConfirmationText() {
            return `You are moving ${this.bulkMoveConfirmationDetails.total_items} items from location '${this.bulkMoveConfirmationDetails.selected_location}' to location '${this.bulkMoveConfirmationDetails.destination_location}'`;
        }
    },

    methods: {
        ...mapActions(['displayToast', 'relocateStockToLocation']),

        barcodeScanned(barcode) {
            if (typeof barcode !== 'object' || !barcode.type) {
                return this.displayToast({text: 'This is not a valid QR code.', type: 'error'});

            } else if (!this.location && barcode.type !== 'location') {
                return this.displayToast({text: 'Please scan a Location QR code.', type: 'error'});

            } else if (!this.location && barcode.type === 'location') {
                // Initial scan of location has been made
                return this.location = barcode;

            } else if (this.location) {
                if (this.scanningType === 'single' && barcode.type === 'location') {
                    return this.displayToast({text: 'Please scan a Stock or Asset QR code.', type: 'error'});

                } else if (this.scanningType === 'bulk' && barcode.type !== 'location') {
                    return this.displayToast({text: 'Please scan a destination Location QR code.', type: 'error'})

                } else if (this.scanningType === 'bulk' && this.location.id === barcode.id) {
                    return this.displayToast({text: 'You must scan another location QR code.', type: 'error'});
                }

                // Complete the relocation
                return this.triggerRelocateStock(barcode);
            }

            return this.displayToast({text: 'There was an error with your selection. Please contact support.', type: 'error'})
        },

        triggerRelocateStock(barcode) {
            if (this.relocating) return;

            this.relocating = true;

            this.relocateStockToLocation({
                locationId: this.location.id,
                scanningType: this.scanningType,
                barcodeId: barcode.id,
                barcodeType: barcode.type,
                bulkMoveConfirmed: this.bulkMoveConfirmed
            }).then(response => {
                if (this.scanningType === 'single') {
                    this.displayToast({text: "Item Successfully Relocated.", type: 'success', duration:3000});
                    this.scannedItems.push(response.data);

                } else if (!this.bulkMoveConfirmed) {
                    this.barcode = barcode;
                    this.bulkMoveConfirmationDetails = response.data;

                } else {
                    this.displayToast({text: `${this.bulkMoveConfirmationDetails.total_items} Items Successfully Relocated.`, type: 'success', duration:3000});
                    this.clearSelections();
                }
                this.relocating = false;
            }).catch(e => {
                this.displayToast({text: e.errors, type: 'error'});
                this.relocating = false;
            });
        },

        confirmBulkMove() {
            this.bulkMoveConfirmed = true;
            this.triggerRelocateStock(this.barcode);
        },

        clearSelections() {
            this.location = null;
            this.barcode = null;
            this.scannedItems = [];
            this.bulkMoveConfirmed = false;
            this.bulkMoveConfirmationDetails = {};
        },

        reloadPage() {
            window.location.reload();
        }
    }
}
</script>

<style scoped>

</style>